<template>
  <div class="main">
    <div class="container-title">
      <a-breadcrumb separator=">" class="breadcrumb">
        <a-breadcrumb-item>
          <a @click.prevent="$router.push('/index')">首页</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click.prevent="$router.push(`/buyercenter/order-management?entityId=${$route.query.entityId}`)">订单管理</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          创建订单
        </a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="container" ref="data">
      <a-card class="l-card">
        <div class="title">采购商</div>
        <a-row type="flex" align="middle">
          <a-col :span="24" class="col">
            <span class="fl"><span class="font-error">* </span>采购商：</span>
            <div class="search">
              {{roleInfo.commercialName}}
            </div>
          </a-col>
          <a-col :span="14" class="card-row" v-if="form.purchaserId">
            <a-col :span="24" class="col">
              <span class="fl"><span class="font-error">* </span>地址标签：</span>
              <div class="search">
                {{ purchaserSite.tagAddr }}
              </div>
            </a-col>
            <a-col :span="24" class="col" style="margin-bottom: 0">
              <span class="fl"><span class="font-error">* </span>收货地址：</span>
              <div class="search">
                {{ purchaserSite.fullAddr }}
              </div>
            </a-col>
            <span class="tool" @click="setSite">修改地址</span>
          </a-col>

          <a-col :span="10" v-if="form.purchaserId">
            <a-space :size="18" style="padding-left: 24px">
              <a-button type="primary" class="w140" @click="siteType = 1;showSite = true">
                <a-icon type="environment" />切换地址
              </a-button>
              <a-button type="primary" class="w140" @click="siteType = 3;showSite = true">
                <a-icon type="environment" />添加地址
              </a-button>
            </a-space>
          </a-col>
        </a-row>
      </a-card>
      <a-card class="l-card">
        <div class="title">供应商</div>
        <a-row type="flex" align="middle">
          <a-col class="col" :span="24">
            <span class="fl"><span class="font-error">* </span>供应商：</span>
            <div class="search">
              <a-space>
                <a-select :disabled="!form.purchaserId" style="width: 380px" showSearch :filter-option="filterOption"
                  :value="form.supplierId" placeholder="请输入供应商关键词进行搜索"
                  @change="form.supplierId = $event;usualaddress()">
                  <a-select-option v-for="d in suppliersList" :key="d.id">
                    {{ d.name }}
                  </a-select-option>
                </a-select>
                <span class="font-error" v-if="saleamount">限额：{{ saleamount.netAmountLimit | transition }}</span>
              </a-space>
            </div>
          </a-col>
          <a-col class="col" :span="24">
            <span class="fl">常用发货地：</span>
            <div class="search">
              <a-select :disabled="!form.purchaserId" placeholder="请选择常用发货地" style="width: 380px"
                @change="selectAddress">
                <a-select-option v-for="d in usualaddressList" :key="d.addrFull">
                  {{ d.addrFull }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
          <a-col class="col" :span="24">
            <span class="fl"><span class="font-error">* </span>发货地：</span>
            <div class="search">
              <a-cascader :disabled="!form.purchaserId" :show-search="{ filter }"
                :field-names="{ value: 'key', label: 'label', children: 'children' }" :value="cascaderValue"
                :options="options" @change="cascaderValue = $event" placeholder="请选择发货地"
                style="width: 240px;margin-right: 12px;" />
              <a-input :disabled="!form.purchaserId" v-model="form.addrDetail" placeholder="请输入详细地址" class="detailed">
              </a-input>
            </div>
          </a-col>
        </a-row>
      </a-card>

      <a-card class="l-card">
        <div class="title">品类信息</div>
        <a-row>
          <a-col :span="24" class="col">
            <span class="fl">
              <span class="font-error">* </span>商品分类：
            </span>
            <div class="search">
              <a-cascader :show-search="{ filter:(inputValue, path) => path.some(option => option.catValue.toLowerCase().indexOf(inputValue.toLowerCase()) > -1) }" popupClassName="category-cascader"
                :field-names="{ value: 'id', label: 'catValue', children: 'children' }" :options="tree"
                @change="classifyChange" placeholder="请选择商品分类" style="width: 358px" />
            </div>
          </a-col>
        </a-row>
      </a-card>

      <a-card class="l-card">
        <div class="title">分类属性</div>
        <a-row>
          <a-col :span="24" class="col" v-for="(item, index) in property" :key="item.id">
            <span class="fl">
              <span class="font-error" v-if="index === 0">*</span> {{ item.attrName }}：
            </span>
            <div class="search">
              <a-space :size="32">
                <a-select show-search :value="item.value" @change="item.value = $event" style="width: 240px"
                  :placeholder="'请选择' + item.attrName">
                  <a-select-option key="No_choice" v-if="index !== 0">
                    暂不选择
                  </a-select-option>
                  <a-select-option v-for="name in item.attrValue" :key="name">
                    {{ name }}
                  </a-select-option>
                </a-select>

                <div v-if="index === 0">
                  <a-switch :checked="form.isSpec" @click="form.isSpec = !form.isSpec" style="margin-right: 16px">
                    <a-icon slot="checkedChildren" type="check" />
                    <a-icon slot="unCheckedChildren" type="close" />
                  </a-switch>
                  <span>发票上是否显示该规格</span>
                </div>
              </a-space>
            </div>
          </a-col>
        </a-row>
      </a-card>

      <a-card class="l-card">
        <div class="title">发票品类</div>
        <a-row>
          <a-col :span="24" class="col">
            <span class="fl">
              <span class="font-error">*</span> 品类名称：
            </span>
            <div class="search">
              <a-space :size="32">
                <a-select style="width: 340px" :value="invoiceInfo.index" @change="invoiceInfo.index = $event"
                  placeholder="请选择品类名称">
                  <a-select-option v-for="(item, index) in invoiceInfo.list" :key="index">
                    {{ item.taxClassName }}
                  </a-select-option>
                </a-select>

                <span>
                  品类编码：{{
                    (invoiceInfo.list.length &&
                      invoiceInfo.index !== undefined &&
                      invoiceInfo.list[invoiceInfo.index].taxClassCode) ||
                      ''
                  }}
                </span>
              </a-space>
            </div>
          </a-col>
          <a-col :span="24" class="col">
            <span class="fl">规格型号：</span>
            <div class="search">
              {{ (property.length && property[0].value) || '' }}
            </div>
          </a-col>
        </a-row>
      </a-card>

      <a-row class="l-card">
        <a-col :span="24"> 
            <a-space :size="24">
              <span class="lh60"> 下游管理 </span>
              <a-select v-model="form.downstreamId" style="width: 320px" :dropdownMatchSelectWidth="false">
                <a-select-option :key="null">
                  无特定下游
                </a-select-option>
                <a-select-option v-for="item in dsList" :key="item.id">
                  {{item.downstreamName}}
                </a-select-option>
              </a-select>
            </a-space>
          </a-col>
      </a-row>

      <a-card class="l-card tax-row">
        <div class="title">发票信息</div>
        <a-row style="width: 100%">
          <a-col :span="10" style="margin-bottom: 0">
            <div class="col">
                <span class="fl">发票抬头：</span>
                <div class="search">{{ billInfo.supplierName }}</div>
              <!-- <span class="label-text">发票抬头：</span>{{ billInfo.supplierName }} -->
            </div>
            <div class="col">
                <span class="fl">开票地址：</span>
                <div class="search">{{ billInfo.invoiceAddr }}</div>    
            </div>
            <div class="col">
                <span class="fl">开户行：</span>
                <div class="search">{{ billInfo.invoiceBankName }}</div>
            </div>
            <div class="col">
                <span class="fl">电子发票邮箱：</span>
                <div class="search">{{ billInfo.invoiceEmail }}</div>
            </div>
          </a-col>
          <a-col :span="10" style="margin-bottom: 0">
            <div class="col">
                <span class="fl">纳税人识别号：</span>
                <div class="search">{{ billInfo.unifiedCode }}</div>
            </div>
            <div class="col">
                <span class="fl">开票联系方式：</span>
                <div class="search">{{ billInfo.invoiceMobile }}</div>
            </div>
            <div class="col">
                <span class="fl">开户行账号：</span>
                <div class="search">{{ billInfo.invoiceBankAcc }}</div>
            </div>
          </a-col>
        </a-row>

        <a-button class="rule edit" size="large" type="primary" @click="$refs.stockBillInfo.visible = true">
          <a-icon type="form" />修改信息
        </a-button>
      </a-card>

      <a-card class="l-card">
        <div class="title">商品信息</div>
        <a-row>
          <a-col :span="24" class="col">
            <span class="fl">
              <span class="font-error">*</span> 货品数量：
            </span>
            <div class="search">
              <a-input-number v-model="form.quantity" @blur="getTaxes" placeholder="请输入货品数量" style="width: 280px"
                :precision="infoTypeObj.value === 3 || infoTypeObj.value === 4 ? 0 : 4">
              </a-input-number>
              <div class="input-number-slot">
                <a-select :value="infoTypeObj.value" @change="infoTypeObj.value = $event" style="width: 70px">
                  <a-select-option v-for="(item, index) in infoTypeObj.list" :value="index" :key="index">
                    {{ item | unit }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </a-col>

          <a-col :span="24" class="col">
            <span class="fl">
              <span class="font-error">*</span> 增值税：
            </span>
            <div class="search">
              <a-select :value="taxRate.id" placeholder="请选择增值税" @change="typeTaxChange" style="width: 350px"
                :dropdownMatchSelectWidth="false">
                <a-select-option v-for="(item) in taxRateList" :key="item.id">
                  {{ item.policyName }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="24" class="col">
            <span class="fl">
              <span class="font-error">*</span> 货物总价：
            </span>
            <div class="search">
              <!-- form.grossAmount -->
              <a-input-number :value="taxObj.netAmount" disabled v-if="estimateModel == 2" style="width: 295px">
              </a-input-number>
              <a-input-number v-model="form.grossAmount"
                :placeholder="`限额 ${infoTypeObj.value === 3 || infoTypeObj.value === 4 ? 98000 : saleamount.netAmountLimit || ''}`"
                v-else :precision="2" @blur="computeBlur" @change="estimateModelChange(1)" style="width: 315px">
              </a-input-number>
              <div class="input-number-slot">
                <span style="padding: 0 10px">元</span>
              </div>

              <span style="margin-left: 32px">价税合计：</span>
              <a-input-number :value="productInfo.totalPrices" disabled v-if="estimateModel == 1" style="width: 295px">
              </a-input-number>
              <a-input-number v-model="form.grossAmount" @blur="computeBlur" @change="estimateModelChange(2)" v-else
                :placeholder="infoTypeObj.value === 3 || infoTypeObj.value === 4 ? '请输入' : `限额${saleamount.contractAmountLimit || ''}`"
                :precision="2" style="width: 295px"></a-input-number>
              <div class="input-number-slot">
                <span style="padding: 0 10px">元</span>
              </div>
              <div class="font-error">*货物总价和价税合计金额二选一输入</div>
            </div>
          </a-col>
          <a-col :span="24" v-if="form.taxRegionPolicyId" class="space-col">
            <a-space :size="42" style="margin-left: 16px;">
              <span>货款单价：{{ unitPrice }} 元/{{ typeText | unit }}</span>
              <span>税款总计：{{ productInfo.witholding }} 元</span>
              <span>{{ taxRate.vatRate * 100 + '%' }}增值税：{{ productInfo.vat }} 元</span>
            </a-space>
            <div>
              <a-space :size="42" style="margin-left: 16px;">
                <span>不含税价：{{ taxObj.taxableAmount }} 元</span>
                <span>合同总价：{{ productInfo.totalPrices }} 元</span>
              </a-space>
            </div>

          </a-col>

          <a-col :span="24" class="col">
            <span class="fl">
              <span class="font-error">*</span> 商品照片：
            </span>
            <div class="search">
              <input v-show="false" id="addImage" ref="addImage" alt="" class="upload" type="file" name="addImage"
                multiple="multiple" accept="image/png,image/jpeg,image/gif,image/jpg" @change="getImgFile" />
              <div class="l-flex" style="margin-top: 10px">
                <div v-for="(item, index) in merchandiseImgList" :key="index" class="product-img"
                  :class="item.isMain && 'is-main'">
                  <img :src="item.imgPath" alt="" />
                  <p class="img-config">
                    <a-space>
                      <a @click="isMain(index, item.isMain)">{{ item.isMain ? '取消设置' : '设为主图' }}</a>
                      <a @click="delImg(index)">删除图片</a>
                    </a-space>
                  </p>
                </div>
                <div class="product-img add-image" @click="selectImg">
                  <a-icon type="plus" />
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-card>
      <a-row class="l-card">
        <a-col :span="24"> 
            <a-space :size="24" style="align-items: inherit">
              <span class="lh60 remark"> &nbsp;&nbsp;&nbsp;&nbsp;备注 </span>
              <a-textarea :maxLength="200" style="width: 395px" placeholder="请输入备注" v-model="form.orderRemark" :auto-size="{ minRows: 2, maxRows: 6 }"></a-textarea>
            </a-space>
          </a-col>
      </a-row>

      <a-row type="flex" justify="center" style="margin: 64px 0 24px 0">
        <a-col>
          <a-space :size="32">
            <a-button style="width: 180px" size="large" @click="$router.back()">返回</a-button>
            <a-button type="primary" style="width: 180px" size="large" :loading="loading" @click="createOrder">创建订单</a-button>
          </a-space>
        </a-col>
      </a-row>

      <stockBillInfo ref="stockBillInfo" :obj="billInfo" @change="getsupplierinf" />
      <site v-if="showSite" :siteType="siteType" :show.sync="showSite" :defaultId="form.deliveryAddressId"
        :purchaserIdId="form.purchaserId" @change="siteChange" />
    </div>
  </div>
</template>

<script>
import options from '@/utils/ara'
import site from './components/SiteCopy'
import { categoryTree } from '@/api/store'
import { suppliers, orderAndMdse } from '@/api/buyer'
import { usualaddress, querySecondAttr, saleamount, queryRegionPolicy, evaluation, queryCateroryName, getsupplierinf } from '@/api/seller'
import { getdefault } from '@/api/order'
import { type } from '@/utils/constArr'
import { uploadPhoto } from '@/api/utils'
import { mapGetters } from 'vuex'
import stockBillInfo from './components/stockBillInfo.vue'
import { dsPur } from '@/api/buyerCenter'

export default {
  components: {
    stockBillInfo,
    site
  },
  name: 'NewOrder',
  data() {
    return {
      options,
      showSite: false,
      siteType: 1,
      taxRateList: [],
      taxObj: { netAmount: '', taxableAmount: '' },
      suppliersList: [],
      purchasersList: [],
      purchaserSite: {},
      usualaddressList: [],
      cascaderValue: [],
      saleamount: '', // 卖家剩余销售额获取
      tree: [],
      loading: false,
      taxRate: {}, // 税率
      property: [],
      invoiceInfo: {
        list: [],
        index: undefined
      },
      // 发票
      billInfo: {
        id: this.$route.query.entityId,
        supplierName: '',
        unifiedCode: '',
        invoiceBankName: '',
        invoiceBankAcc: '',
        invoiceMobile: '',
        invoiceEmail: '',
        invoiceAddr: ''
      },
      // 单位
      infoTypeObj: {
        list: ['T', 'K', 'G', 'S', 'C'],
        value: 0
      },
      merchandiseImgList: [], // 上传的照片
      // 商品信息
      productInfo: {
        num: '',
        price: '',
        vat: 0,
        surcharge: 0,
        witholding: 0,
        totalPrices: 0
      },
      form: {
        supplierId: undefined,
        purchaserId: undefined,
        quantity: '', // 商品数量
        addrDetail: undefined, // 详细地址
        categorySecondId: undefined, // 二级商品品类ID
        taxRegionPolicyId: undefined, // 区域政策id
        deliveryAddressId: undefined, // 买家地址id
        grossAmount: undefined, // 商品总价
        isSpec: true, // 是否显示规格
        downstreamId: null,
        orderRemark: null
      },
      estimateModel: false,
      dsList: [] // 下游管理列表
    }
  },
  filters: {
    unit: (val) => type[val],
    transition(val) {
      if (val > 10000) {
        return (val / 10000).toFixed(2) + '（万）'
      }
      return val
    }
  },
  mounted() {
    this.form.purchaserId = this.$route.query.entityId
    // 获取默认采购商地址
    getdefault({ purchaserId: this.form.purchaserId }).then(res => {
      this.purchaserSite = res.data || {}
      this.form.deliveryAddressId = res.data && res.data.id
    })
    // 获取买家对应的卖家列表
    suppliers({ purchaserId: this.form.purchaserId }).then(res => {
      this.suppliersList = this.removeDuplicate(res.data)
      console.log(2222,this.suppliersList)
    })
    // 品类树
    categoryTree().then((res) => {
      res.data.forEach(item => {
        if (!item.children || item.children.length === 0) {
          item.disabled = true
        }
      })
      this.tree = res.data
    })
    dsPur(this.$route.query.entityId).then(res => {
      if (res.code === 200) {
        this.dsList = res.data
      }
    })
    // 获取买家发票信息
    this.getsupplierinf()
  },
  computed: {
    ...mapGetters(['getRoleP']),
    roleInfo() {
      return this.getRoleP && this.getRoleP.find(item => item.entityId == this.$route.query.entityId) || {}
    },
    // 商品单价
    unitPrice() {
      if (!this.form.quantity || !this.form.grossAmount) return 0
      return Math.round(this.form.grossAmount / this.form.quantity * 100) / 100
    },
    typeText() {
      return this.infoTypeObj.list[this.infoTypeObj.value]
    }
  },
  beforeRouteLeave(to, form, next) {
    to.name === 'OrderList' && (to.meta.refresh = true)
    next()
  },
  methods: {
    filter(inputValue, path) {
      console.log(path);
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    rowClassName(record, index) {
      return index === 2 ? 'check-color' : ''
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    purchaserChange() {
      this.suppliersList = []
      this.form.deliveryAddressId = undefined
      this.purchaserSite = {}
      this.form.supplierId = undefined
      getdefault({ purchaserId: this.form.purchaserId }).then(res => {
        this.purchaserSite = res.data || {}
        this.form.deliveryAddressId = res.data && res.data.id
      })
      this.fetchUser()
    },
    setSite() {
      this.siteType = 2
      if (!this.form.deliveryAddressId) {
        this.$message.error('请先切换地址')
        return
      }
      this.showSite = true
    },
    siteChange(e) {
      console.log(e)
      if (e.id === this.form.deliveryAddressId) {
        getdefault({ purchaserId: this.form.purchaserId }).then(res => {
          this.purchaserSite = res.data || {}
          this.form.deliveryAddressId = res.data && res.data.id
        })
        return
      }
      this.form.deliveryAddressId = e.id
      this.purchaserSite = e
    },
    fetchPurchasers(value) {
      console.log(value)
      // purchasers({ searchText: value }).then(res => {
      //     this.purchasersList = res.data
      // })
    },
    fetchUser(value) {
      console.log(value)
      suppliers({ searchText: value, purchaserId: this.form.purchaserId }).then(res => {
        this.suppliersList = this.removeDuplicate(res.data)
        console.log(22222222222222222222,this.suppliersList)
      })
    },
    removeDuplicate (arr) {
      let len = arr.length
      for (let i = 0; i < len; i++) {
          for (let j = i + 1; j < len; j++) {
            if (arr[i].id === arr[j].id) {
            arr.splice(j, 1)
            len--
            j--
          }
        }
      }
      return arr
    },
    usualaddress() {
      usualaddress(this.form.supplierId).then(res => {
        if (res.code === 200) {
          this.usualaddressList = res.data
        }
      })
      queryRegionPolicy(this.form.supplierId).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.message)
        } else {
          if (!res.data || !res.data.length) {
            this.$message.error('请设置供应商区域政策')
            return
          }
          this.taxRateList = res.data
          const { id, policyName, vatRate } = res.data[0]
          this.form.taxRegionPolicyId = id
          this.taxRate = { id, policyName, vatRate }
        }
      })
      this.saleamount = ''
      saleamount(this.form.supplierId).then(res => {
        if (res.code === 200) {
          this.saleamount = res.data
        }
      })
    },
    //  发票管理
    getsupplierinf() {
      getsupplierinf({
        entityCharacter: 'P',
        id: this.$route.query.entityId
      }).then((res) => {
        if (res.code === 200 && res.data) {
          this.billInfo = {
            id: this.$route.query.entityId,
            supplierName: '',
            unifiedCode: '',
            invoiceBankName: '',
            invoiceBankAcc: '',
            invoiceMobile: '',
            invoiceEmail: '',
            invoiceAddr: '',
            ...res.data
          }
        }
      })
    },
    selectAddress(e) {
      const data = this.usualaddressList.find(item => item.addrFull === e)
      this.cascaderValue = [data.provinceCode, data.cityCode, data.areaCode]
      const list = data.addrFull.split(' ')
      let address = ''
      list.forEach((item, index) => {
        if (index > 2) {
          address = address + item
        }
      })
      this.form.addrDetail = address
    },
    cascaderChange(e) {
      console.log(e)
    },
    classifyChange(e) {
      // 获取二级分类id
      const id = e[1]
      this.form.categorySecondId = id
      // 获取二级分类的属性
      querySecondAttr(id).then((res) => {
        const data = res.data
        data.forEach((item, index) => {
          if (item.attrValue) {
            item.attrValue = item.attrValue.split(',')
            item.value = index === 0 ? item.attrValue[0] : undefined
          }
        })
        this.property = data
        console.log('分类属性', this.property)
      })
      // 查找发票信息
      queryCateroryName(id).then((res) => {
        console.log('发票信息', res)
        this.invoiceInfo.list = res.data
        // 查找默认值
        if (!res.data.length) {
          this.invoiceInfo.index = undefined
        } else {
          const index = this.invoiceInfo.list.findIndex((item) => item.isDefault)
          this.invoiceInfo.index = index !== -1 ? index : 0
        }
      })
    },
    estimateModelChange(e) {
      this.estimateModel = e
      if (!this.form.grossAmount) {
        this.estimateModel = 0
      }
    },
    async getTaxes() {
      try {
        if (!this.form.quantity || !this.form.grossAmount) return
        const res = await evaluation(this.form.supplierId, this.form.grossAmount, this.taxRate.id, this.estimateModel === 2 ? 'contractAmount' : 'netAmount')
        const result = res.data[0] || {}
        this.productInfo.vat = result.vatAmount
        this.productInfo.witholding = result.taxAmount
        this.productInfo.totalPrices = result.contractAmount
        this.taxObj = { netAmount: result.netAmount, taxableAmount: result.taxableAmount }
      } catch (err) {
        console.log(err)
      }
    },
    // 设置主图
    isMain(index, b) {
      if (b) {
        // 取消主图
        this.merchandiseImgList[index].isMain = 0
      } else {
        // 设置主图
        this.merchandiseImgList.forEach((item, i) => {
          item.isMain = 0
          if (index === i) {
            item.isMain = 1
          }
        })
      }
    },
    // 删除图片
    delImg(index) {
      this.merchandiseImgList.splice(index, 1)
    },
    // 获取上传的img文件
    getImgFile(e) {
      console.log('上传的照片', Array.from(e.target.files))
      if (e.target.files[0].type.indexOf('image') === -1) {
        this.$message.error('请上传正确的格式')
        e.target.value = null
        return
      }
      if (e.target.files[0]) {
        this.uploadImage(Array.from(e.target.files))
        console.log('上传的照片', this.merchandiseImgList)
        e.target.value = null
      }
    },
    // 选择图片
    selectImg() {
      this.$refs.addImage.dispatchEvent(new MouseEvent('click'))
    },
    // 上传图片
    uploadImage(images) {
      console.log(images[0])
      const file = images[0]
      uploadPhoto(file, (url, imgName) => {
        this.merchandiseImgList.push({ imgPath: url, imgName, isMain: 0, sortNo: images.length })
        images.splice(0, 1)
        if (images.length > 0) {
          this.uploadImage(images)
        }
      })
    },
    async computeBlur() {
      await this.getTaxes()
      await this.quota()
    },
    async quota() {
      if (this.estimateModel === 1 && this.form.grossAmount > this.saleamount.netAmountLimit) {
        this.$message.error('货物总价金额大于450万限额')
      } else if (this.estimateModel === 2 && this.form.grossAmount > this.saleamount.contractAmountLimit) {
        this.$message.error('价税合计金额大于450万限额')
      } else if ((this.infoTypeObj.value === 3 || this.infoTypeObj.value === 4) && ((this.estimateModel === 2 ? this.taxObj.netAmount : this.form.grossAmount) > 98000)) {
        this.$message.error('货物总价金额大于9.8万')
      }
    },
    typeTaxChange(e) {
      console.log(e)
      this.form.taxRegionPolicyId = e
      const { id, policyName, vatRate } = this.taxRateList.find(item => item.id === e)
      this.taxRate = { id, policyName, vatRate }
      this.getTaxes()
    },
    createOrder() {
      // 转换二级分类属性参数
      const attributes = {}
      this.property.forEach(item => {
        attributes[item.attrName] = item.value === 'No_choice' ? null : item.value
      })
      const config = {
        attributes: JSON.stringify(attributes), // 二级分类属性,
        areaCode: this.cascaderValue[2], // 区编码
        specName: this.property.length && this.property[0].value || ' ', // 商品规格（可以勾选不显示规格，则用空格代替）
        unitsMeasure: this.typeText, // 单位
        categorySecondTaxCodeId: this.invoiceInfo.list.length && this.invoiceInfo.list[this.invoiceInfo.index].id, // 商品品类税务id
        merchandiseImgList: this.merchandiseImgList,
        ...this.form,
        grossAmount: this.estimateModel === 2 ? this.taxObj.netAmount : this.form.grossAmount
      }
      console.log(config)
      if (!config.purchaserId) {
        this.$message.error('采购商名称不能为空')
        return
      } else if (!config.supplierId) {
        this.$message.error('供应商名称不能为空')
        return
      } else if (!config.deliveryAddressId) {
        this.$message.error('请选择采购商地址')
        return
      } else if (!config.areaCode) {
        this.$message.error('请选择发货地')
        return
      } else if (!config.addrDetail) {
        this.$message.error('请补充详细地址')
        return
      } else if (!config.categorySecondId) {
        this.$message.error('请选择商品品类')
        return
      } else if (!config.quantity) {
        this.$message.error('请填写货品数量')
        return
      } else if (!config.grossAmount) {
        this.$message.error('请填写货品总价')
        return
      } else if ((this.infoTypeObj.value === 3 || this.infoTypeObj.value === 4) && ((this.estimateModel === 2 ? this.taxObj.netAmount : config.grossAmount) > 98000)) {
        this.$message.error('商品数量单位为台、件，货物总价不允许大于9.8万'); return
      } else if (this.estimateModel === 2 ? this.taxObj.netAmount > this.saleamount.netAmountLimit : config.grossAmount > this.saleamount.netAmountLimit) {
        this.$message.error('货物总价大于450万限额'); return
      }
      this.loading = true
      orderAndMdse(config).then(res => {
        console.log(res)
        this.loading = false
        if (res.code === 200) {
          this.$message.success(res.message)
        //   this.$router.push(`/order?id=${res.data}&entityId=${config.purchaserId}`)
         this.$router.push(`/succee-order?purchaserId=${this.$route.query.entityId}&id=${res.data}`)
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  max-width: 1440px;
  min-width: 990px;
  margin: 0 auto;
  padding: 0 15px;
}
.container-title {
  margin: auto;
  width: 1100px;
  @media screen and (max-width: 1440px) {
    width: 100%;
    max-width: 1440px;
    min-width: 990px;
  }
}
.container {
  padding: 42px 30px;
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  margin: 0 auto;
  margin-bottom: 64px;
  width: 1100px;
  @media screen and (max-width: 1440px) {
    width: 100%;
  }
  .row {
    margin: 0 auto;
    padding-left: 14px;
    width: 100%;
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    margin-bottom: 28px;
  }
  .col {
    margin-bottom: 24px;
  }
  .fl {
    float: left;
    line-height: 32px;
    width: 86px;
    text-align: right;
  }
  .search {
    margin-left: 86px;
    line-height: 32px;
  }
  .detailed {
    width: 420px;
  }

  .l-card {
    position: relative;
    width: 100%;
    margin-bottom: 50px;
    color: rgba(0, 0, 0, 0.85);
    .card-row {
      position: relative;
      border-right: 1px solid #ccc;
      margin-bottom: 0 !important;
      .tool {
        cursor: pointer;
        color: #1890ff;
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translate(0, -50%);
      }
    }
    .title {
      content: '分类属性';
      position: absolute;
      top: -12px;
      left: 16px;
      background: #fff;
      z-index: 1;
      font-weight: bold;
      padding: 0 12px;
      color: #000;
      font-size: 16px;
    }
    /deep/ .ant-col {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .label-left {
      line-height: 32px;
      text-align: right;
      margin-bottom: 0;
    }
    .l-flex {
      display: flex;
      flex-wrap: wrap;
      p {
        margin-bottom: 0;
      }
    }
    .product-img {
      border: 1px solid #e4e4e4;
      width: 150px;
      min-height: 135px;
      margin-right: 20px;
      margin-top: 20px;
      .img-config {
        text-align: center;
        border-top: 1px solid #e4e4e4;
        background: #f9fafc;
        line-height: 36px;
        font-size: 12px;
      }
      img {
        width: 100%;
        height: 135px;
      }
      &:nth-child(-n + 4) {
        margin-top: 0;
      }
    }
    .add-image {
      height: 135px;
      user-select: none;
      cursor: pointer;
      text-align: center;
      i {
        color: #cccccc;
        line-height: 135px;
        font-size: 42px;
      }
    }
  }
}
/deep/ .ant-table-thead > tr > th {
  font-weight: 700;
}
/deep/ .check-color {
  background: #fafafa;
}
/deep/ .ant-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-number-slot {
  background: #f2f2f2;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  vertical-align: top;
  // padding: 0 8px;
  border: 1px solid #d9d9d9;
  border-left: none;
  border-radius: @border-radius-base;
  /deep/ .ant-select-selection {
    background-color: transparent;
    border: none;
  }
  /deep/ .ant-select-open .ant-select-selection {
    border: none;
    box-shadow: none;
  }
  /deep/ .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    border: none;
    box-shadow: none;
  }
}
.no-show-input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.font-error {
  color: #f04844;
}
.is-main {
  border: 1px solid #f04844 !important;
}
.w140 {
  width: 150px;
  height: 40px;
  color: #999;
  background: rgb(245, 245, 245);
  border: none;
  &:hover {
    color: rgb(51, 51, 51);
  }
}
/deep/ .ant-card-body {
  padding: 24px 0 24px 52px;
}
.space-col {
  padding-left: 68px;
  /deep/ .ant-space-item {
    width: 200px;
  }
}

.tax-row {
  .fl {
    float: left;
    line-height: 32px;
    width: 110px;
    text-align: right;
  }
  .search {
    margin-left: 110px;
    line-height: 32px;
  }
  /deep/ .ant-card-body {
    padding: 24px;
  }
  .rule {
    position: absolute;
    right: 96px;
    bottom: 24px;
  }
  .col {
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .label-text {
    color: #999999;
  }
  .edit {
    width: 150px;
  }
}
.lh60 {
  font-size: 16px;
  font-weight: 400;
  color: #666;
  line-height: 60px;
}
.remark {
  line-height: 32px;
}
</style>
