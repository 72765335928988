<template>
  <!-- 发票信息 -->
  <a-modal
    :centered="true"
    :width="600"
    :visible="visible"
    cancelText="取消"
    okText="保存"
    @ok="invoiceOk"
    @cancel="visible = false"
  >
    <a-row>
      <a-col align="center" class="fs16">发票信息</a-col>
    </a-row>
    <a-row>
      <a-col>发票抬头</a-col>
      <a-col class="pt5"
        ><a-input
          disabled
          v-model="form.supplierName"
        ></a-input
      ></a-col>
    </a-row>
    <a-row class="pt10">
      <a-col>纳税人识别号</a-col>
      <a-col class="pt5"
        ><a-input disabled v-model="form.unifiedCode"></a-input
      ></a-col>
    </a-row>
    <a-row class="pt10">
      <a-col>发票地址</a-col>
      <a-col class="pt5"
        ><a-input
          @input="form.invoiceAddr = $event.target.value.replace(/^\s+|\s+$/g,'')"
          v-model="form.invoiceAddr"
        ></a-input
      ></a-col>
    </a-row>
    <a-row class="pt10">
      <a-col>发票电话</a-col>
      <a-col class="pt5"
        ><a-input
          :maxLength="18"
          v-model="form.invoiceMobile"
          @input="form.invoiceMobile = $event.target.value.replace(/[^\d|-]/g,'')"
        ></a-input
      ></a-col>
    </a-row>
    <a-row class="pt10">
      <a-col>发票银行名称</a-col>
      <a-col class="pt5"
        ><a-input
          @input="form.invoiceBankName = $event.target.value.replace(/^\s+|\s+$/g,'')"
          v-model="form.invoiceBankName"
        ></a-input
      ></a-col>
    </a-row>
    <a-row class="pt10">
      <a-col>发票银行账号</a-col>
      <a-col class="pt5"
        ><a-input
          :maxLength="30"
          @input="form.invoiceBankAcc = $event.target.value.replace(/^\s+|\s+$/g,'')"
          v-model="form.invoiceBankAcc"
        ></a-input
      ></a-col>
    </a-row>
    <a-row class="pt10">
      <a-col>电子发票邮箱</a-col>
      <a-col class="pt5"
        ><a-input v-model="form.invoiceEmail"></a-input
      ></a-col>
    </a-row>
    <a-row class="pt10">
      <a-col>发票须知：</a-col>
      <a-col>1. 订单付款后纳税人识别号不可修改，请确认信息后付款；</a-col>
      <a-col
        >2.
        在订单完成后申请开票，纸制发票我方会统一开票后寄给买方，电子发票会通过邮件发送。</a-col
      >
      <a-col>3. 发票金额为实际支付金额。</a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { updateinvoice } from '@/api/seller'
export default {
    props: {
        obj: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            visible: false,
            form: {
                entityCharacter: "P",
                id: 15,
                invoiceAddr: "石家庄12312",
                invoiceBankAcc: "6213321312312312311",
                invoiceBankName: "浙商银行",
                invoiceEmail: "1244060112@qq.com",
                invoiceMobile: "0571-89002281"
            }
        }
    },
    watch: {
        visible(val) {
            if (val) {
                console.log(this.obj);
                this.form = Object.assign({}, this.obj)
            }
        }
    },
    methods: {
        // 修改发票信息
        invoiceOk() {
            if(!this.form.invoiceBankName){
                this.$message.error('发票银行名称不能为空')
            }else if(!this.form.invoiceBankAcc){
                this.$message.error('发票银行账户不能为空')
            }else if(!this.form.invoiceMobile){
                this.$message.error('发票电话不能为空')
            }else if(!this.form.invoiceEmail){
                this.$message.error('电子发票邮箱不能为空')
            }else if(!this.form.invoiceAddr){
                this.$message.error('发票地址不能为空')
            }else {
                updateinvoice({
                    entityCharacter: 'P',
                    ...this.form,
                }).then((res) => {
                    if (res.code === 200) {
                    this.visible = false
                    this.$emit('change')
                    this.$message.success(res.message)
                    } else {
                    this.$message.error(res.message)
                    }
                })
            }
            
        },
    }
}
</script>

<style lang="less" scoped>
.fs16 {
  font-size: 16px;
}
.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
</style>